<!--start dialog template -->
<template>
  <v-row justify="center">
    <v-dialog :value="dialog" persistent max-width="600px">
      <v-card>
        <v-toolbar flat color="primary" dark>
          <v-toolbar-title>
            <span class="headline">{{ title }} Grading System</span>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid">
              <v-row>
                <v-col cols="12" sm="12" md="3">
                  <v-text-field
                    label="Min Score"
                    v-model="formData.min_score"
                    required
                    :rules="isValid.minScore"
                    v-uppercase
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <v-text-field
                    label="Max Score"
                    v-model="formData.max_score"
                    required
                    :rules="isValid.name"
                    v-uppercase
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <v-text-field
                    label="Grade"
                    v-model="formData.grade"
                    required
                    :rules="isValid.name"
                    v-uppercase
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <v-text-field
                    label="Point/Weight"
                    v-model="formData.points"
                    required
                    :rules="isValid.name"
                    v-uppercase
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="Description"
                    v-model="formData.descriptions"
                    required
                    :rules="isValid.description"
                    v-uppercase
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-select
                    return-object
                    :items="levels"
                    :item-text="'name'"
                    label="Examination Level"
                    v-model="formData.level"
                    required
                    :rules="isValid.level"
                    v-uppercase
                  ></v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" lg="12" md="12" sm="12">
                  <v-radio-group
                    label="Make it Default for All Schools?"
                    v-model="formData.isDefault"
                  >
                    <v-layout align-start row>
                      <v-radio
                        label="Yes"
                        color="primary"
                        :value="true"
                      ></v-radio>
                      <v-radio label="No" :value="false" color="red"></v-radio>
                    </v-layout>
                  </v-radio-group>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" lg="12" md="12" sm="12">
                  <v-checkbox
                    label="Click here to create your own Grading System"
                    class="pt-1  pb-1"
                    v-model="formData.is_current"
                  >
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <DialogButtons
            :close="close"
            :save="add"
            :loading="loading"
            :valid="valid"
            :title="title"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<!--end dialog-->

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      required: false,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
    isEditing: {
      type: Boolean,
      required: true,
    },
    formData: {
      type: Object,
      required: true,
    },

    levels: {
      type: Array,
      required: true,
    },

    closeDialog: {
      type: Function,
      required: true,
    },
    save: {
      type: Function,
      required: true,
    },
  },

  name: "SchoolClassesDialog",
  data: () => ({
    valid: true,
    isValid: {
      minScore: [
        (v) => !!v || " Name required",
        /// (v) => /.+[^#<>:;,?""*|/]+/.test(v) || 'Invalid Character',
      ],
      description: [(v) => !!v || " Name required"],
      level: [(v) => !!v || " Level required"],
    },
  }),
  methods: {
    add() {
      let data = { ...this.formData };

      this.save(data);
    },

    close() {
      this.closeDialog();
    },
  },
  computed: {
    title() {
      return this.formData.id ? "Update" : "Create";
    },
  },
  mounted() {},
};
</script>
